<template>
  <div id='about'>
    <Header :currentIndex="id"/>
    <h1 class="about_title">ABOUT US</h1>
    <div class="line"></div>
    <!-- Our Mission -->
    <div v-if="dataInfo.length" class="mission">
      <div class="mission_title">
        <p>{{dataInfo[0].class_name}}</p>
        <p>{{dataInfo[0].class_subhead_name}}</p>
      </div>
      <div class="interval"></div>
      <div v-html="dataInfo[0].class_intro" class="mission_text"></div>
      <img :src="dataInfo[0].back_pic" alt="" class="mission_img">
      <!-- OUR VISION -->
      <p class="mission_item_title">{{dataInfo[1].class_name}}</p>
      <div class="interval"></div>
      <div v-html="dataInfo[1].class_intro" class="mission_text text_left"></div>
      <img :src="dataInfo[1].back_pic" alt="" class="mission_vision_img">
      <!-- OUR VALUES -->
      <p class="mission_item_title">{{dataInfo[2].class_name}}</p>
      <div class="interval"></div>
      <div v-html="dataInfo[2].class_intro" class="mission_text"></div>
      <div class="mission_values">
        <img :src="dataInfo[2].back_pic" alt="" class="mission_values_img">
        <div class="mission_values_main">
          <p class="mission_values_title">MEIFAN AUSTRALIA 4P principles of staff management.</p>
          <div class="mission_values_content">
            <div v-for="(item, index) in dataInfo[2].child" :key="index" class="mission_values_item">
              <div class="mission_values_index">{{`0${index + 1}`}}</div>
              <div class="mission_values_name">{{item.title}}</div>
              <div v-html="item.content" class="mission_values_text"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- OUR CULTURE -->
      <p class="mission_item_title">{{dataInfo[3].class_name}}</p>
      <div class="interval"></div>
      <div v-html="dataInfo[3].class_intro" class="mission_text"></div>
      <div class="mission_culture">
        <div v-for="item in dataInfo[3].child" :key="item.id" class="mission_cultrue_item">
          <img :src="item.icon" alt="" class="mission_culture_img">
          <p class="mission_culture_text">{{item.title}}</p>
        </div>
      </div>
    </div>
    <div v-else class="no_data"></div>
    <div class="bg_left"><img src="@assets/image/bg_left.png" alt=""></div>
    <div class="bg_right"><img src="@assets/image/bg_right.png" alt=""></div>
    <Footer :currentIndex="id" />
  </div>
</template>

<script>
import Header from '@cpt/Header'
import Footer from '@cpt/Footer'
import {getContent} from '@api'

export default {
  data () {
    return {
      id: 2,
      dataInfo: []
    }
  },
  components: {
    Header,
    Footer
  },
  created() {
    this.getData(this.id)
  },
  methods: {
    getData(id) {
      getContent({id}).then(res => this.dataInfo = res.data)
    }
  }
} 
</script>

<style lang='less' scoped>
#about {
  position: relative;
  overflow: hidden;
  .about_title {
    width: 1200px;
    margin: 178px auto 0;
    font-size: 49px;
    font-weight: bold;
    animation: move 1s;
  }
  @keyframes move {
    0% {
      transform: translateX(-500px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  .line {
    position: absolute;
    top: 305px;
    right: 0;
    width: 60%;
    height: 1px;
    background: #e5e5e5;
    z-index: -1;
  }
  .mission {
    width: 1200px;
    margin: 133px auto 0;
    .mission_title {
      font-size: 28px;
      text-align: center;
      p {
        font-weight: bold;
      }
    }
    .interval {
      width: 1px;
      height: 62px;
      background: #e5e5e5;
      margin: 32px auto;
    }
    .mission_text {
      font-size: 14px;
      text-align: center;
    }
    .mission_img {
      display: block;
      width: 770px;
      height: 530px;
      margin: 72px auto 0;
    }
    .mission_item_title {
      margin-top: 72px;
      font-size: 28px;
      font-weight: bold;
      text-align: center;
    }
    .mission_vision_img {
      display: block;
      width: 1200px;
      height: 240px;
      margin-top: 72px;
    }
    .mission_values {
      position: relative;
      left: -360px;
      width: 1560px;
      height: 893px;
      .mission_values_img {
        width: 1400px;
        height: 598px;
        margin-top: 72px;
      }
      .mission_values_main {
        position: absolute;
        top: 383px;
        right: 0;
        width: 575px;
        height: 510px;
        background: #fff;
        box-shadow: 0px 0px 20px #ccc;
        .mission_values_title {
          padding: 32px 32px 0 32px;
          font-size: 18px;
          font-weight: 600;
        }
        .mission_values_content {
          margin: 0 32px;
          .mission_values_item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 97px;
            border-bottom: 1px dashed #e5e5e5;
            .mission_values_index {
              flex: 1;
              font-size: 18px;
              font-weight: 600;
            }
            .mission_values_name {
              flex: 3;
              font-size: 18px;
              font-weight: 600;
            }
            .mission_values_text {
              flex: 8;
              color: #666;
              font-size: 14px;
            }
            &:nth-child(1) .mission_values_index {
              color: #366FE0;
            }
            &:nth-child(2) .mission_values_index {
              color: #E0883B;
            }
            &:nth-child(3) .mission_values_index {
              color: #46C25D;
            }
            &:nth-child(4) .mission_values_index {
              color: #705AE0;
            }
            &:last-child {
              border: none;
            }
          }
        }
      }
    }
    .mission_culture {
      margin: 72px 0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .mission_cultrue_item {
        width: 20%;
        text-align: center;
        .mission_culture_img {
          display: inline-block;
          width: 40px;
          height: 40px;
          text-align: center;
        }
        .mission_culture_text {
          margin-top: 65px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
  .bg_left {
    position: absolute;
    top: 90px;
    left: 0;
    z-index: -1;
  }
  .bg_right {
    position: absolute;
    top: 875px;
    right: 0;
    z-index: -1;
  }
  .no_data {
    width: 100%;
    height: 143px;
  }
}
</style>